import { navigate } from 'gatsby'
import { useEffect } from 'react'

export default () => {
    useEffect(() => {
        navigate('/en/404', { replace: true })
    }, [])

    return null
}
